<script>
	import Base from '@backend/Base.vue';
	import draggable from 'vuedraggable'
	import BOGen from '@helper/BOGen';
	export default {
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "ProjectHome",
				PageName: "ProjectHome",
				about: {},
				advInput: {},
				adv: [],
				data: [],
				unit: {},
				article: [],
				facilities: [],
				fac: {},
			}
		},
		computed: {

		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			endDrag() {
				BOGen.apirest('/' + this.Name, {
					data: this.data,
					type: 'sortSlider'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			endDragAdv() {
				BOGen.apirest('/' + this.Name, {
					data: this.adv,
					type: 'sortAdv'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			endDragFacilities() {
				BOGen.apirest('/' + this.Name, {
					data: this.facilities,
					type: 'sortFacilities'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			submitFormSlider(e, callback) {
				if (e && e.btnLoading()) return;
				this.row.as_project = App.$route.params.id
				BOGen.apirest("/" + this.Name, this.row, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger")
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							$('[href="#collapseExample"]').click();
							this.refreshData()
						}, 2000)
						return Gen.info(resp.message, "success", 1000).then(() => {
							this.$router.push({
								name: this.Name,
								params: {
									id: this.$route.params.id
								}
							})
						})
					}
				}, "POST")
			},
			addSlide() {
				this.row = {
					as_is_active: "Y",
				}
				this.row.type = 'addSlider'
				$('#collapseExample').addClass('show')
			},
			editSlide(v) {
				$('#collapseExample').addClass('show')
				this.row = v;
				this.row.type = 'updateSlider'
			},
			deleteItemId(id) {
				var v = id
				swal({
					title: "Are you sure to delete " + this.ObjectName + "?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "deleteSlider",
							id: v
						}, () => {
							swal("Deleted!", "Information has been deleted.", "success");
							this.refreshData()
						}, "POST")
					}
				});
			},
			submitAbout(e, callback) {
				if (e && e.btnLoading()) return;
				this.about.sc_project = App.$route.params.id
				this.about.type = 'editAbout'
				BOGen.apirest("/" + this.Name, this.about, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger", 1000, '.info-about')
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							$('[href="#collapseExample"]').click();
							this.refreshData()
						}, 2000)
						return Gen.info(resp.message, "success", 1000, '.info-about').then(() => {
							this.$router.push({
								name: this.Name,
								params: {
									id: this.$route.params.id
								}
							})
						})
					}
				}, "POST")
			},
			editAdv(v) {
				$('#collapseAdv').addClass('show')
				this.advInput = v
				this.advInput.type = 'editAdv'
			},
			addAdv() {
				this.advInput = {
					mpa_is_active: 'Y',
					type: 'addAdv'
				}
				$('#collapseAdv').addClass('show')
			},
			submitFormAdventage(e, callback) {
				if (e && e.btnLoading()) return;
				this.advInput.mpa_project = App.$route.params.id
				BOGen.apirest("/" + this.Name, this.advInput, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger", 1000, '.info-adv')
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							$('[href="#collapseAdv"]').click();
							this.refreshData()
						}, 2000)
						return Gen.info(resp.message, "success", 1000, '.info-adv').then(() => {
							this.$router.push({
								name: this.Name,
								params: {
									id: this.$route.params.id
								}
							})
						})
					}
				}, "POST")
			},
			deleteAdv(id) {
				var v = id
				swal({
					title: "Are you sure to delete " + this.ObjectName + "?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "deleteAdv",
							id: v
						}, () => {
							swal("Deleted!", "Information has been deleted.", "success");
							this.refreshData()
						}, "POST")
					}
				});
			},
			editFac(v) {
				$('#collapseFac').addClass('show')
				this.fac = v
				this.fac.type = 'editFac'
			},
			addFac() {
				$('#collapseFac').addClass('show')
				this.fac = {
					mpf_is_active: 'Y',
					type: 'addFac'
				}
			},
			deleteFac(id) {
				var v = id
				swal({
					title: "Are you sure to delete " + this.ObjectName + "?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "deleteFac",
							id: v
						}, () => {
							swal("Deleted!", "Information has been deleted.", "success");
							this.refreshData()
						}, "POST")
					}
				});
			},
			submitFormFac(e, callback) {
				if (e && e.btnLoading()) return;
				this.fac.mpf_project = App.$route.params.id
				BOGen.apirest("/" + this.Name, this.fac, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger", 1000, '.info-fac')
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							$('[href="#collapseFac"]').click();
							this.refreshData()
						}, 2000)
						return Gen.info(resp.message, "success", 1000, '.info-fac').then(() => {
							this.$router.push({
								name: this.Name,
								params: {
									id: this.$route.params.id
								}
							})
						})
					}
				}, "POST")
			},
		},
		watch: {
			'unit.awc_value'(v) {
				if (v == true) {
					this.unit.awc_value = 'Y'
					this.unitCheck = false
				} else if (v == false) {
					this.unit.awc_value = 'N'
					this.unitCheck = true
				}
				this.unit.type = "editUnit"
				setTimeout(() => {
					BOGen.apirest("/" + this.Name, this.unit, (err, resp) => {}, "POST")
				}, 2000)
			},
			'article.awc_value'(v) {
				if (v == true) {
					this.article.awc_value = 'Y'
					// this.showArticle=false
				} else if (v == false) {
					this.article.awc_value = 'N'
					// this.showArticle=true
				}
				this.article.type = "editArticle"
				setTimeout(() => {
					BOGen.apirest("/" + this.Name, this.article, (err, resp) => {}, "POST")
				}, 2000)

			}
		}

	};
</script>
<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		<div class="topnavBackoffice">
			<router-link :to="{name:'ProjectHome',params:{id:$route.params.id}}" class="active">Beranda</router-link>
			<router-link :to="{name:'ProjectProducts',params:{id:$route.params.id}}">Produk</router-link>
			<router-link :to="{name:'ProjectAbout',params:{id:$route.params.id}}">Tentang Kami</router-link>
			<a href="javascript:;" class="dropdown_bo">Artikel & Progres
				<ul class="dropdown_menu">
					<li>
						<router-link :to="{name:'ProjectNews',params:{id:$route.params.id}}">Artikel</router-link>
					</li>
					<li>
						<router-link :to="{name:'ProjectProgress',params:{id:$route.params.id}}">Perkembangan Proyek</router-link>
					</li>
				</ul>
			</a>
			<router-link :to="{name:'ProjectContact',params:{id:$route.params.id}}">Lokasi & Kontak</router-link>
		</div>

		<div class="row">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Slide Show</h5>
						<draggable v-model="data" class="row" tag="tbody" @end="endDrag">

							<div style="cursor:move;" class="col-md-3" :id="'dat'+v.as_id" v-for="(v,k) in data" :key="k">
								<div class="wrap_slider_img">
									<img :src="uploader(v.as_img)" class="img-responsive" />
									<div class="slider_name">
										<p>{{v.as_title}}</p>
									</div>
									<a data-toggle="collapse" @click="editSlide(v)" href="javascript:;" class="bullet_edit"><i
											class="fas fa-pencil-alt"></i></a>
									<a href="javascript:;" @click="deleteItemId(v.as_id,'dat'+v.as_id)" class="bullet_delete"><i
											class="far fa-trash-alt"></i></a>
									<span class="label label-success" v-if="v.as_is_active == 'Y'">Active</span>
									<span class="label label-danger" v-else>Inactive</span>
								</div>
							</div>

							<div class="col-md-3">
								<a class="wrap_upload" data-toggle="collapse" @click="addSlide" href="#collapseExample" role="button"
									aria-expanded="false" aria-controls="collapseExample">
									<div class="ic_wrap">
										<i class="fas fa-plus"></i>
										<p>New Slider</p>
									</div>
								</a>
							</div>
						</draggable>
						<VForm @resp="submitFormSlider" method="post">
							<div class="row collapse mt-4" id="collapseExample">
								<div class="col-12 info"></div>
								<div class="col-md-4 mb-3">
									<BoField name="as_img" mandatory>
										<Uploader :param="{thumbnail:true}" name="as_img" v-model="row.as_img" type="slideshow"
											uploadType="cropping"></Uploader>
									</BoField>
									<BoField name="as_img_alt" v-model="row.as_img_alt"></BoField>
								</div>
								<div class="col-md-8 mb-3">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Heading</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-md-9">
															<BoField name="as_title" v-model="row.as_title" mandatory>
															</BoField>
														</div>
														<div class="col-md-9">
															<BoField name="as_desc">
																<textarea v-model="row.as_desc" rows="4" class="form-control"></textarea>
															</BoField>
														</div>

														<div class="col-md-9">
															<BoField name="as_btn_label" v-model="row.as_btn_label" mandatory>
															</BoField>
														</div>

														<div class="col-md-9">
															<BoField name="as_btn_link" :attr="{type:'url'}" v-model="row.as_btn_link" mandatory>
															</BoField>
														</div>

														<div class="col-md-9">
															<BoField name="as_is_active">
																<div class="row">
																		<radio name="as_is_active" v-model="row.as_is_active" option="Y"
																			:attr="validation('as_is_active')">
																			Active</radio>
																		<radio name="as_is_active" v-model="row.as_is_active" option="N">
																			Inactive</radio>
																</div>
															</BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-4 mt-3">
											<button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>

				<!-- End Slider -->

				<!-- Start About -->
				<div class="row">
					<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title">About Us</h5>
								<VForm @resp="submitAbout" method="post">
									<div class="row">
										<div class="col-md-12 info-about"></div>
										<div class="col-md-8 max_120">
											<BoField name="sc_desc" mandatory>
												<CKEditor name="content" class="form-control" v-model="about.sc_desc"></CKEditor>
											</BoField>
										</div>
										<div class="col-md-4">
											<!-- <BoField name="sc_background" mandatory>
												<Uploader :param="{thumbnail:true}" name="sc_background" v-model="about.sc_background"
													type="background" uploadType="cropping"></Uploader>
											</BoField> -->
											<input type="hidden" v-model="about.sc_id">
											<input type="hidden" v-model="about.sc_page">
										</div>
										<div class="col-md-12 text-right"><button type="submit"
												class="btn btn-rounded btn-info btn-loading">Update About Home</button></div>
									</div>
								</VForm>
							</div>
						</div>
					</div>
				</div>
				<!-- End About -->


				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Mengapa Memilih</h5>
						<draggable v-model="adv" class="row" tag="tbody" @end="endDragAdv">

							<div style="cursor:move;" class="col-md-2" :id="'dat'+va.as_id" v-for="(va,ka) in adv" :key="ka">
								<div class="wrap_slider_img">
									<img :src="uploader(va.mpa_img)" class="img-responsive" />
									<div class="slider_name">
										<p>{{va.mpa_title}}</p>
									</div>
									<a data-toggle="collapse" @click="editAdv(va)" href="javascript:;" class="bullet_edit"><i
											class="fas fa-pencil-alt"></i></a>
									<a href="javascript:;" @click="deleteAdv(va.mpa_id)" class="bullet_delete"><i
											class="far fa-trash-alt"></i></a>
									<span class="label label-success" v-if="va.mpa_is_active == 'Y'">Active</span>
									<span class="label label-danger" v-else>Inactive</span>
								</div>
							</div>

							<div class="col-md-2">
								<a class="wrap_upload" data-toggle="collapse" @click="addAdv" href="javascript:;" role="button"
									aria-expanded="false" aria-controls="collapseAdv">
									<div class="ic_wrap">
										<i class="fas fa-plus"></i>
										<p>New Advantage</p>
									</div>
								</a>
							</div>
						</draggable>
						<VForm @resp="submitFormAdventage" method="post">
							<div class="row collapse mt-4" id="collapseAdv">
								<div class="col-12 info-adv"></div>
								<div class="col-md-4 mb-3">
									<BoField name="mpa_img" mandatory>
										<Uploader :param="{thumbnail:true}" name="mpa_img" v-model="advInput.mpa_img" type="adventage"
											uploadType="cropping"></Uploader>
									</BoField>
									<BoField name="mpa_img_alt" v-model="advInput.mpa_img_alt"></BoField>
								</div>
								<div class="col-md-8 mb-3">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Content</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-md-9">
															<BoField name="mpa_title" v-model="advInput.mpa_title"></BoField>
														</div>
														<div class="col-md-9">
															<BoField name="mpa_desc">
																<textarea v-model="advInput.mpa_desc" rows="4" class="form-control"></textarea>
															</BoField>
														</div>
														<div class="col-md-9">
															<BoField name="mpa_is_active">
																<div class="row">
																		<radio name="mpa_is_active" v-model="advInput.mpa_is_active" option="Y"
																			:attr="validation('mpa_is_active')">
																			Active</radio>
																		<radio name="mpa_is_active" v-model="advInput.mpa_is_active" option="N">
																			Inactive</radio>
																</div>
															</BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-4 mt-3">
											<button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
				<!-- Show Unit -->
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Show Unit</h5>
						<div class="row">
							<div class="col-md-2 max_120">
								Show Section?
							</div>
							<div class="col-md-2">
								<div class="row">
									<radio name="awc_value" v-model="unit.awc_value" option="Y" :attr="validation('awc_value')">
										Yes</radio>
									<radio name="awc_value" v-model="unit.awc_value" option="N">
										No</radio>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- End Show Unit -->

				<!-- Facilitas -->
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Our Facilities</h5>
						<draggable v-model="facilities" class="row" tag="tbody" @end="endDragFacilities">

							<div style="cursor:move;" class="col-md-2" :id="'dat'+vf.as_id" v-for="(vf,kf) in facilities" :key="kf">
								<div class="wrap_slider_img">
									<img :src="uploader(vf.mpf_img)" class="img-responsive" />
									<div class="slider_name">
										<p>{{vf.mpf_title}}</p>
									</div>
									<a data-toggle="collapse" @click="editFac(vf)" href="javascript:;" class="bullet_edit"><i
											class="fas fa-pencil-alt"></i></a>
									<a href="javascript:;" @click="deleteFac(vf.mpf_id)" class="bullet_delete"><i
											class="far fa-trash-alt"></i></a>
									<span class="label label-success" v-if="vf.mpf_is_active == 'Y'">Active</span>
									<span class="label label-danger" v-else>Inactive</span>
								</div>
							</div>

							<div class="col-md-2">
								<a class="wrap_upload" data-toggle="collapse" @click="addFac" href="javascript:;" role="button"
									aria-expanded="false" aria-controls="collapseFac">
									<div class="ic_wrap">
										<i class="fas fa-plus"></i>
										<p>New Facilities</p>
									</div>
								</a>
							</div>
						</draggable>

						<VForm @resp="submitFormFac" method="post">
							<div class="row collapse mt-4" id="collapseFac">
								<div class="col-12 info-fac"></div>
								<div class="col-md-4 mb-3">
									<BoField name="mpf_img" mandatory>
										<Uploader :param="{thumbnail:true}" name="mpf_img" v-model="fac.mpf_img" type="facilities"
											uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-md-8 mb-3">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Content</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-md-9">
															<BoField name="mpf_title" v-model="fac.mpf_title" mandatory></BoField>
															<BoField name="mpf_img_alt" v-model="fac.mpf_img_alt"></BoField>
														</div>
														<div class="col-md-9">
															<BoField name="mpf_is_active">
																<div class="row">
																		<radio name="mpf_is_active" v-model="fac.mpf_is_active" option="Y"
																			:attr="validation('mpf_is_active')">
																			Active</radio>
																		<radio name="mpf_is_active" v-model="fac.mpf_is_active" option="N">
																			Inactive</radio>
																</div>
															</BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-4 mt-3">
											<button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
				<!-- End Facilitas -->

				<!-- Show Unit -->
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Show Latest Article</h5>
						<div class="row">
							<div class="col-md-2 max_120">
								Show Section?
							</div>
							<div class="col-md-2">
									<div class="row">
											<radio name="awc_" v-model="article.awc_value" option="Y" :attr="validation('awc_value')">
												Yes</radio>
											<radio name="awc_" v-model="article.awc_value" option="N">
												No</radio>
									</div>
							</div>
						</div>
					</div>
				</div>

				<!-- End Show Unit -->
			</div>
		</div>
	</div>
</template>